  .core-issue-analysis {
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 5px; /* Updated to 5px */
    padding: 20px;
    margin: 20px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #FFF; /* Updated to #FFF */
    box-shadow: 1px 2px 5px 0px rgba(255, 255, 255, 0.1); /* Added box-shadow */
  }

  .core-issue-header {
    display: flex;
    align-items: center;
    align-self: flex-start; /* This will align the header to the left */
    margin-left: 12px;
  }

  .core-issue-header img {
    margin-right: 8px;
  }
  

  .core-issue-header h4 {
    font-size: 0.9em;
    color: #0D4373 !important; 
    font-family: 'Inter', sans-serif !important; /* Updated to include fallback font */
    font-style: normal !important;
    font-weight: bold !important; /* Updated to bold */
    line-height: 1;
  }

  .aggregate-ace h3 {
    font-size: 0.9em;
    color: #0D4373 !important; 
    font-family: 'Inter', sans-serif !important; /* Updated to include fallback font */
    font-style: normal !important;
    font-weight: bold !important; /* Updated to bold */
    line-height: 1;
    margin-left: 20px;
  }

  .aggregate-ace p {
    color: #BCBCBC;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    margin-left: 20px;
  }

  .ace-header,
.frequency-header {
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  background: #E7E7E7;
  padding-left: 10px;
  width: 100%;
}

.ace-header p,
.frequency-header p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-left: 8px !important;
  text-transform: uppercase !important; /* To match your design */
  color: #4a4a4a !important; /* Adjust as needed */
  margin-top: 12px !important;
}

/* You might need additional CSS to style the dropdown icon */
.ace-header img,
.frequency-header img {
  cursor: pointer;
}
/* Add any other specific styles you want for the paragraphs or container elements */


  .analysis-container {
    display: flex;
    border-radius: 0px 0px 5px 5px;
    width: 100%; /* ensure the container takes the full width */
  }

  .analysis-container > * {
    flex: 1;
    min-width: 0; /* prevent flex items from growing past content size */
    margin-right: 20px;
  }

  .analysis-container > *:last-child {
    margin-right: 0;
  }

  .ace-container,
.frequency-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  align-items: flex-start;
  /* Set a specific flex-basis if you want a fixed width or use flex-grow */
   overflow: hidden; /* Ensures content does not overflow */
}

  .ace-container p {
    margin-top: 12px;
    color: #83659E;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px; /* 125% */
  }
  
  .buttons-container {
    display: flex;
    flex-wrap: wrap; /* Add flex-wrap property */
    justify-content: left;
    gap: 10px;
    margin-top: 10px;
  }

  .button-item {
    border-radius: 18px;
    padding: 8px 15px;
    border: 1px solid transparent;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #83659E;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px; /* 125% */
  }
  
  .button-item.selected {
    border-radius: 20px;
    border: 1px solid #9D71C5;
    background: linear-gradient(135deg, #E2E5FF 5.73%, #E5D8FD 91.15%);
  }
  
  .word-map {
    margin-top: 20px;
    width: 100%; /* Adjust as needed for responsiveness */
    height: auto;
  }
  
  .find-button {
    margin-top: 1px;
    cursor: pointer;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .core-issue-analysis {
      width: 90%;
      margin: 10px;
    }
  
    .buttons-container {
      flex-direction: column;
    }
  
    .button-item {
      width: 100%;
    }
  }

  