.top-container {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 5px; /* Updated to 5px */
  padding: 20px;
  margin: 20px;
  width: 50%; /* Set width to 50% */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #FFF; /* Updated to #FFF */
  box-shadow: 1px 2px 5px 0px rgba(255, 255, 255, 0.1); /* Added box-shadow */
}

.header {
  display: flex;
  align-items: center;
  align-self: flex-start; /* This will align the header to the left */
  margin-left: 12px;
}

.header img {
  margin-right: 8px;
}

.header h4 {
  font-size: 0.9em;
  color: #0D4373 !important; 
  font-family: 'Inter', sans-serif !important; /* Updated to include fallback font */
  font-style: normal !important;
  font-weight: bold !important; /* Updated to bold */
  line-height: normal;
}

.content-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  align-items: flex-start;
  /* Set a specific flex-basis if you want a fixed width or use flex-grow */
   overflow: hidden; /* Ensures content does not overflow */
   width: 100%;
}

  .content-container p {
    color: #7D7D7D;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px; /* 125% */
  }

  .content-header {
  display: flex;
  align-items: center;
  border-radius: 5px 5px 0px 0px;
  background: #D6DAFE;
  padding-left: 10px;
  width: 100%;
}

.content-header p {
  font-size: 12px !important;
  font-weight: 600 !important;
  margin-left: 8px !important;
  text-transform: uppercase !important; /* To match your design */
  color: #4a4a4a !important; /* Adjust as needed */
  margin-top: 12px !important;
  padding-right: 12px;
}

/* You might need additional CSS to style the dropdown icon */
.content-header img {
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 10px 20px; /* Updated gap property */
  padding: 10px;
}

.grid-item h3 {
  /* Add styles for each grid item */
  color: #A5A5A5;
  font-family: Inter;
  font-size: 0.8em;
  font-style: normal;
  font-weight: 500;
  line-height: 1.0; /* Updated line-height */
}

.grid-item h5 {
  /* Add styles for each grid item */
  color: #182A72;
  font-family: Inter;
  font-size: 0.8em;
  font-style: normal;
  font-weight: 700;
  line-height: 1.0; /* Updated line-height */
}