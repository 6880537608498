/* ChatWindow.css */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.chat-window {
  background: #FFFFFF;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: calc(100% - 250px);
  margin-top: 50px;
  margin-bottom: 20px;
  position: relative;
}

.chat-header {
  padding: 10px 20px;
  border-bottom: 1px solid #ECECEC;
  flex-shrink: 0;
}

.chat-partner-name {
  /* background: #f0f0f0; */
  border-radius: 20px;
  padding: 5px 0px;
  margin: 0;
  font-size: 16px;
}

.chat-messages {
  flex-grow: 1;
  padding: 15px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.chat-message {
  background: #F7F7F7;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;
  max-width: 60%;
  align-self: flex-start;
}

.message-content {
  margin-bottom: 5px;
}

.message-meta {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
}

.message-author {
  font-weight: bold;
}

.message-time {
  color: #999999;
}

.chat-input {
  border-top: 1px solid #ECECEC;
  padding: 10px;
  display: flex;
  flex-shrink: 0;
}

.chat-input textarea {
  flex: 1;
  border: none;
  border-radius: 10px;
  padding: 10px;
  margin-right: 10px;
}

.chat-input button {
  font-family: var(--Typeface-family-display, Inter);
  font-size: 16px;

  background: #D6DAFE;
  background: var(--Gradient-MediumBlue, linear-gradient(99deg, #3271BF -29.19%, #0D9CC9 111.09%));
  color: #FFF;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 600;
}

.chat-message.right {
  align-self: flex-end;
}

.toggle-button-container {
  padding: 0 0 5px 13px;
}