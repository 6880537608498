/* InfoPanel.css */
.Panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  border-radius: 15px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.panel-row-2 {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel-button-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
  border-bottom: 3px solid #E5E5E5;
}

.child-button {
  border-radius: 5px 5px 0px 0px;
  background: #DDDDDF;
  padding: 10px;
  margin-right: 6px;
  border-radius: 5px 5px 0px 0px;
}

.child-button p {
  color: #182A72;
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.child-button:first-child {
  background: #F6F6F6;
  padding-top: 30px;
  margin-right: 9px;
  margin-left: 27px;
  
}

.memory-child-button {
  background: #FFF;
  border-radius: 5px 5px 0px 0px;
  padding: 10px;
  margin-right: 6px;
  border-radius: 5px 5px 0px 0px;
  color: #182A72;
  text-align: center;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.child-button:last-child {
  margin-left: auto;
  background-color: #F6F6F6;
  cursor: pointer;
}