/* Montserrat.css */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap');
/* CSS rules for Montserrat font styles */

/* Poppins.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
/* CSS rules for Poppins font styles */

/* Inter.css */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
/* CSS rules for Poppins font styles */


.home {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  background-color: #F5F6FA;
  height: 100vh;
  overflow: auto;

  font-family: Inter;
}

.main-content {
  display: flex;
  align-items: flex-start;
  gap: 50px;
  flex: 1 0 0;
  align-self: stretch;
}

.left-sidebar {
  /* width: 30%; */
  background-color: #F6F7FC;
  font-weight: bold;
  color: #333;
  height: 100%;
}

.content {
  width: 40%;
  height: 90vh;
  min-width: 700px;
  display: flex;
  flex-direction: column;
}

.right-sidebar {
  width: 40%;
  padding: 20px;
}

.left-sidebar {
  position: relative;
}

.PAPA {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 145px;
  margin-right: 15px;
}