.resource-cards {
    overflow: auto;
}

.top-resource {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.20);
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    overflow: hidden;
    /* This will clip all overflowing children */
    align-items: center;
    width: fit-content;
}

.PAPA-Bulk {
    display: flex;
    overflow: auto;
    box-sizing: border-box;
}

.PAPA-Sidebar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    padding: 20px 6px;
    align-self: stretch;
    background-color: rgba(255, 255, 255, 0.9);
}

.PAPA-Content {
    width: 100%;
    padding: 20px 15px 15px 15px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.20);
}

.sidebar-button {
    display: flex;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: transparent;
    cursor: pointer;
    border-radius: 30px;
    background: linear-gradient(0deg, #DBDEEA 0%, #DBDEEA 100%), linear-gradient(135deg, #F7E0FF 11.72%, #FFE5E3 90.63%);
}

.sidebar-button.selected {
    border-radius: 30px;
    border: 1px solid #DEA4D1;
    background: linear-gradient(135deg, #F7E0FF 11.72%, #FFE5E3 90.63%);
}

.sidebar-button path {
    fill: #A0A2B8;
}

.sidebar-button.selected path {
    fill: url(#paint0_linear_4221_905);
}

.drawing-border {
    border: 2px solid #FEE0DE;
    animation: drawBorder 1s linear forwards;
}

@keyframes drawBorder {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.resource-cards {
    width: 300px;
    height: 500px;
    overflow-y: auto;
    background: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.resource-header-container {
    display: flex;
    align-items: center;
}

.resource-child.expanded {
    display: flex;
    width: 100%;
    padding: 5%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 5px;
    background: #EFEFEF;
    margin-top: 10px;
}

.back-icon {
    padding: 5px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(0deg, #E2E5FF 0%, #E2E5FF 100%), #B6DDEA;
    color: var(--Colour-Periwinkle, #707EFF);
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 6px;
    /* 100% */
    margin-right: 3px;
    cursor: pointer;
}

.resource-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
}

.resource-icon {
    padding: 7px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: linear-gradient(0deg, #E2E5FF 0%, #E2E5FF 100%), #B6DDEA;
    color: var(--Colour-Periwinkle, #707EFF);
}

/* Card Header Tags */

.card-header-tag {
    display: inline-flex;
    height: 20px;
    padding: 0 6px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 3px;

    /* filter button text */
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 6px;
    /* 100% */
}

.program-icon {
    padding: 7px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background: linear-gradient(0deg, #CAF7D4 0%, #CAF7D4 100%), linear-gradient(135deg, #E2E5FF 5.73%, #E5D8FD 91.15%), #B6DDEA;
    color: #46C290;
    font-family: Inter;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: 6px;
    /* 100% */
}

.resource-tag {
    color: var(--Colour-Periwinkle, #707EFF);
    background: #E3E3FF;
}

.program-tag {
    color: #46C290;
    background: #CAF7D4;
}

.high-impact-match {
    background: linear-gradient(133deg, #C53EF6 -22.74%, #FF7461 123.29%);
    border-radius: 10px;
    padding: 3px 8px;
    margin-left: 3px;
    color: #FFF;
    font-size: 0.8em;
    font-weight: bold;
}

.resource-content {
    color: #182A72;
    cursor: pointer;
    width: 100%;
}

.main-title {
    color: #182A72;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    border-bottom: 1px solid #EBEBEB;
    padding-bottom: 11px;
    line-height: normal;
}

.main-buttons {
    display: flex;
    gap: 10px;
    margin-top: 10px;
}

.main-buttons-container {
    display: flex;
    gap: 10px;
    margin-top: 10px;
    padding-left: 12px;
}

.filter-button {
    display: inline-flex;
    height: 35px;
    padding: 0 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 0px;
    cursor: pointer;
    color: #182A72;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
}

.res-filter {
    background: #D6DAFE;
}

.prog-filter {
    background: #BAEAC5;
}

.prac-filter {
    background: #FFEDAB;
}

.res-filter.selected {
    border: 2px solid var(--Colour-Periwinkle, #707EFF);
}

.prog-filter.selected {
    border: 2px solid var(--Colour-Periwinkle, #46C290);
}

.prac-filter.selected {
    border: 2px solid var(--Colour-Periwinkle, #EDBB0C);
}

.reload {
    margin-top: 10px;
    width: 72%;
    display: flex;
    padding: 5px 8px;
    justify-content: center;
    align-items: center;
    gap: 9px;
    color: #83659E;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 20px;
    border: 1px solid #83659E;
    background: #FFF;
    cursor: pointer;
}

.title {
    font-weight: bold;
    margin-bottom: 10px;
}

.tags-container {
    display: flex;
    gap: 5px;
    align-self: stretch;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.tag {
    display: flex;
    background-color: #FFF;
    border-radius: 2px;
    padding: 3px 5px;
    color: #686EA5;
    font-family: Inter;
    font-weight: 600;
    font-size: 0.7em;
}

.summary {
    font-size: 0.75em;
    color: #666;
    margin-bottom: 10px;
}

.CTA {
    display: flex;
    justify-content: space-between;
}

.icon-link {
    cursor: pointer;
    background-color: #0D4373;
    border-radius: 20px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.save-link {
    margin-left: auto;
}