.navbar {
  display: flex;
  height: 10vh;
  padding: 1.5625rem var(--Window_V_Padding, 3.125rem);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--UI-LightBlue2, #D0E6EB);
}

.peerlogo {
  width: 53px;
  height: 40px;
}

.nav-logo-container {
  display: flex;
  width: 70px;
  height: 70px;
  padding-top: 0.3125rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  border-radius: 0.625rem;
  background: var(--UI-White, #FFF);
}

.nav-items {
  display: flex;
  padding: 20px 50px;
  align-items: center;
  gap: 40px;
}

.nav-item {
  display: flex;
  height: 50px;
  padding: 15px 15px;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
}

.nav-item:hover {
  background: #B8DAE2;
  cursor: pointer;
}

.nav-title {
  color: var(--UI-DarkTeal, #0080A9);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.active {
  background: #B8DAE2;
}

.nav-profile {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}

.nav-user-info {
  display: flex;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 4px;
}

.nav-logout {
  color: var(--UI-DarkTeal, #0080A9);
  background: none;
  cursor: pointer;
  border: none;
  padding: 0;

  font-size: var(--Typeface-size-body2, 14px);
  font-family: var(--Typeface-family-text, Inter);
  font-weight: 700;
}

.logout-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.nav-name {
  color: var(--Text-DarkNavy, #182A72);
  font-size: var(--Typeface-size-h6, 20px);
  font-style: normal;
  font-weight: 600;
  margin: 0;
}

.profile-icon {
  display: flex;
  width: 55px;
  height: 55px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: var(--Component-Size-minCard_H, 100px);
  background: var(--Gradient-MediumBlue, linear-gradient(99deg, #3271BF -29.19%, #0D9CC9 111.09%));
}