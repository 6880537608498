/* Sidebar container */
.sidebar {
  display: flex;
  width: 350px;
  height: 100%;
  padding: var(--Window_V_Padding, 50px);
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  background-color: #FFF;
}

.sidebar-title {
  color: var(--Text-DarkNavy, #182A72);
  font-family: var(--Typeface-family-display, Inter);
  font-size: var(--Typeface-size-h4, 32px);
  font-style: normal;
  font-weight: 600;
  line-height: var(--Typeface-size-h4, 32px);
  margin: 0;
  /* 100% */
}

.sidebar-divider {
  width: 100%;
  height: 1px;
  background: var(--Text-MediumGray, #A2ACBC);
}

.sidebar-links {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Link styling in the sidebar */
.sidebar button {
  color: var(--ui-dark-purple, #4E2375);
  font-family: 'Montserrat';
  font-size: 15px;
  font-style: italic;
  font-weight: 700;
  text-decoration: none;
  text-align: left;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 8px;
  background-color: #FFF;
  transition: background-color 0.3s;
}

/* Hover effect on links */
.sidebar button:hover {
  background-color: #F0F0F0;
}

/* Active link styling */
.sidebar-link.active {
  width: 100%;
  background-color: #D6D4E0;
  color: #4E2375;
  font-weight: bold;
}

.sidebar-logo {
  width: 120px;
  height: 39px;
  flex-shrink: 0;
  margin-top: auto;
  margin-left: 10px;
}

/* Logout button styling */
.logout-button {
  border-radius: 46px;
  background: var(--gradient-periwinkle, linear-gradient(135deg, #707EFF 0%, #7F3DF6 100%));
  color: #FFF;
  text-align: center;
  font-family: Montserrat;
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  padding: 0.5rem 2rem;
  cursor: pointer;
  border: none;
  margin-bottom: 20px;
  width: 30%;
  height: calc(1.5vw + 20px);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease;
  margin-left: 7px;
}

/* Hover effect on logout button */
.logout-button:hover {
  background: #FFF;
  color: var(--gradient-periwinkle, linear-gradient(135deg, #707EFF 0%, #7F3DF6 100%));
  box-shadow: 0px 0px 10px rgba(112, 126, 255, 0.3);
}