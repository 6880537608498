.signin-container {
    display: flex;
    height: 100vh;
    align-items: stretch;
}

.left-column {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    margin-top: 250px;
    margin-left: 100px;
}

.right-column {
    width: 60%;
    background-image: url('login.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;  /* Ensure that the image doesn't repeat */
}

.logo {
    width: 120px;
    height: 39px;
    margin-left: 10px;
}

.sign-in-text {
    color: #22232E;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
}

.email-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    color: #83659E;
    font-family: Montserrat;
    font-size: 12px !important;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: -0.24px;
    border: 1px solid #83659E;
    border-radius: 5px;
}

.sign-in-button {
    width: auto;
    padding: 0.5rem 2rem;
    border-radius: 46px;
    background: linear-gradient(135deg, #707EFF 0%, #7F3DF6 100%);
    color: #FFF;
    text-align: center;
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 36px;
    border: none;
    cursor: pointer;
    transition: 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    outline: none;
    height: calc(2.5vw + 20px);
}

.sign-in-button:hover {
    background-color: #FFF;
    box-shadow: 0px 0px 10px rgba(112, 126, 255, 0.3);
}

.sign-in-button:hover::before {
    content: "";
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border-radius: 46px;
    background: linear-gradient(135deg, #7F3DF6 0%, #707EFF 100%);
    z-index: -1;
}

.sign-in-button:active {
    transform: scale(0.95);
}
